export const ADD_TO_CART = "ADD_TO_CART";
export const INCREMENT_CART = "INCREMENT_CART";
export const DECREMENT_CART = "DECREMENT_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const SET_HOSPITAL_ID = "SET_HOSPITAL_ID";
export const SET_HOSPITAL_KEY = "SET_HOSPITAL_KEY";
export const SET_HOSPITAL_OBJECT = "SET_HOSPITAL_OBJECT";
export const SET_LOCATION = "SET_LOCATION";
export const SET_TERMINAL = "SET_TERMINAL";
export const SET_ROOM = "SET_ROOM";
export const RESET_ALL = "RESET_ALL";
