<script lang="ts">
import { IPackage } from "@/service/payment/IPaymentApi";
import { useCart } from "@/service/cart/useCart";
import { defineComponent, PropType, ref } from "vue";
import clsx from "clsx";
import PanaceaCounter from "@/component/PanaceaCounter.vue";
import useAlert from "@/service/alert/useAlert";

export default defineComponent({
  components: { PanaceaCounter },
  props: {
    pkg: {
      type: Object as PropType<IPackage>,
      required: true,
    },
  },
  setup(props) {
    const cart = useCart();
    const pkg = props.pkg;
    const quantity = ref<number>(1);
    const alert = useAlert();

    return {
      clsx,
      pkg,
      quantity,
      incQuantity() {
        quantity.value += 1;
      },
      decQuantity() {
        if (quantity.value == 1) {
          return;
        }
        quantity.value -= 1;
      },
      onAdd(pkg: IPackage) {
        for (let i = 0; i < quantity.value; i++) {
          cart.addToCart(pkg);
        }
      },
      expandDetails(pkg: IPackage) {
        if (pkg.description.trim().length === 0) {
          return
        }
        alert.expandDetails(pkg)
      }
    };
  },
});
</script>

<template>
  <div
    class="relative flex flex-col w-full h-full bg-white select-none md:shadow-lg"
  >
    <div class="flex-1 px-3 pt-3">
      <p class="text-xl font-bold overflow-hidden"
      style="white-space: nowrap; text-overflow: ellipsis"
      @click="expandDetails(pkg)"
      >{{ pkg.name }}</p>
      <p
        class="overflow-hidden font-light"
        style="white-space: nowrap; text-overflow: ellipsis"
        @click="expandDetails(pkg)"
      >
        {{ pkg.description }}
      </p>
    </div>

    <div class="my-2 text-center">
      <div class="mx-auto mb-2 text-3xl text-primary border-primary">
        ${{ pkg.noTaxesPrice.toFixed(2) }}
      </div>
      <p class="text-xs lg:text-sm italic font-light text-gray-400 uppercase">
        Packages are non refundable
      </p>
    </div>
    <div class="flex items-center justify-center text-lg">
      <panacea-counter
        class="mb-6"
        :quantity="quantity"
        @on-increment="incQuantity"
        @on-decrement="decQuantity"
      />
    </div>

    <button
      class="flex justify-center w-full p-2 text-lg text-white transition-colors duration-200 bg-primary hover:bg-primary-dark"
      @click="onAdd(pkg)"
    >
      Add To Cart
    </button>
  </div>
</template>
