
import { computed, defineComponent, onMounted, ref } from "vue";
import PanaceaCard from "@/component/PanaceaCard.vue";
import Loading from "@/component/layout/LoadingLayout.vue";
import { useRoute } from "vue-router";
import usePaymentApi from "@/service/payment/usePaymentApi";
import { ILocation, IRoom, ITerminal } from "@/service/payment/IPaymentApi";
import PanaceaSelect from "@/component/input/PanaceaSelect.vue";
import PanaceaButton from "@/component/input/PanaceaButton.vue";
import router, { PACKAGES_ROUTE } from "@/router";
import useAlert from "@/service/alert/useAlert";
import { useStore } from "vuex";
import * as MutTypes from "@/store/mutation-types";

type SelectComponent = typeof PanaceaSelect;

export default defineComponent({
  components: {
    PanaceaSelect,
    PanaceaButton,
    PanaceaCard,
    Loading
  },
  setup(props, ctx) {
    const store = useStore();
    const route = useRoute();
    const payment = usePaymentApi();
    const alert = useAlert();

    const locationSelect = ref<SelectComponent>();
    const roomSelect = ref<SelectComponent>();
    const bedSelect = ref<SelectComponent>();
    const nextButton = ref<SelectComponent>();

    const locations = ref<ILocation[]>([]);
    const rooms = ref<IRoom[]>([]);
    const beds = ref<ITerminal[]>([]);

    const selectedLocation = ref<ILocation>();
    const selectedRoom = ref<IRoom>();
    const selectedBed = ref<ITerminal>();

    const roomsDisabled = computed(() => selectedLocation.value === void 0);
    const bedsDisabled = computed(() => selectedRoom.value === void 0);
    const submitDisabled = computed(
      () => !selectedLocation.value || !selectedRoom.value || !selectedBed.value
    );

    const apiKey = route.params.apiKey as string;
    const hospitalId = route.params.hospitalId as string;

    async function loadLocations() {
      try {
        let data = await payment.loadLocations(hospitalId)
        if (data.length === 0) {
          alert.showErrorMessage('No locations returned for this hospital!')
          return
        }
        locations.value = data;
      } catch (e) {
        alert.showErrorMessage(`Something went wrong - Unable to load locations!`)
      }
    }

    function handleLocationChange(location: ILocation) {
      selectedLocation.value = location;
      rooms.value = location.rooms;
      roomSelect.value?.focus();
    }

    function handleRoomChange(room: IRoom) {
      selectedRoom.value = room;
      beds.value = room.beds;
      bedSelect.value?.focus();
    }

    function handleBedChange(bed: ITerminal) {
      selectedBed.value = bed;
      nextButton.value?.focus();
    }

    function handleSubmit() {
      const terminalId = selectedBed.value?.id;
      const roomId = selectedRoom.value?.name;
      const locationId = selectedLocation.value?.name;
      if (!terminalId || !roomId || !locationId) {
        return;
      }
      store.commit(MutTypes.SET_LOCATION, locationId);
      store.commit(MutTypes.SET_ROOM, roomId);
      store.commit(MutTypes.SET_TERMINAL, terminalId);

      router.push({
        name: PACKAGES_ROUTE,
        params: {
          apiKey,
          hospitalId,
          terminalId,
        },
      });
    }

    return {
      loadLocations,
      locations,
      rooms,
      beds,
      locationSelect,
      roomSelect,
      bedSelect,
      roomsDisabled,
      bedsDisabled,
      submitDisabled,
      handleLocationChange,
      handleRoomChange,
      handleBedChange,
      handleSubmit,
      nextButton,
    };
  },
});
