
import { CHECKOUT_ROUTE } from "@/router";
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    return {
      cartLength: computed(() => store.state.cart.length),
    };
  },
});
