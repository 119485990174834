
import { defineComponent, ref, onMounted } from "vue";
import PanaceaCard from "@/component/PanaceaCard.vue";
import AppBarLayout from "@/component/layout/AppBarLayout.vue";
import LoadingLayout from "@/component/layout/LoadingLayout.vue";
import { IHospital } from "@/service/payment/IPaymentApi";
import usePaymentApi from "@/service/payment/usePaymentApi";
import router, { SELECTION_ROUTE } from "@/router";
import { useStore } from "vuex";
import * as MutTypes from "@/store/mutation-types";

export default defineComponent({
  components: { AppBarLayout, LoadingLayout, PanaceaCard },
  setup() {
    const payment = usePaymentApi();
    const hospitals = ref<IHospital[]>([]);
    const store = useStore();
    store.commit(MutTypes.SET_HOSPITAL_OBJECT, null);

    async function loadHospitals() {
      const data = await payment.loadHospitals();
      hospitals.value = data;
    }

    function handleSubmit(hospital: IHospital) {
      const apiKey = hospital.apiKey;
      const hospitalId = hospital.id;
      const h = hospitals.value.find((x) => x.id === hospital.id);
      store.commit(MutTypes.SET_HOSPITAL_OBJECT, h);
      store.commit(MutTypes.SET_HOSPITAL_ID, hospital.id);
      store.commit(MutTypes.SET_HOSPITAL_KEY, hospital.apiKey);

      router.push({
        name: SELECTION_ROUTE,
        params: {
          apiKey,
          hospitalId,
        },
      });
    }

    onMounted(() => {
      store.commit(MutTypes.RESET_ALL)
    })

    return {
      loadHospitals,
      hospitals,
      handleSubmit,
    };
  },
});
