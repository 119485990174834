
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import PanaceaButton from "@/component/input/PanaceaButton.vue";
export default defineComponent({ 
  components: { PanaceaButton },
  setup() {
    const route = useRoute();
    const queryParams = route.query;
    return {
      queryParams,
    }
  }
});
