<script lang="ts">
import { computed, defineComponent, nextTick, ref } from "vue";
import clsx from "clsx";

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const button = ref<HTMLButtonElement>();
    const finalClass = computed(() =>
      clsx(
        "shadow-lg w-full text-center px-3 py-2 text-white duration-200 rounded-lg outline-none focus:outline-none transition-color",
        props.disabled
          ? "bg-gray-300 text-gray-400 cursor-not-allowed"
          : "bg-primary-dark hover:bg-primary cursor-pointer"
      )
    );
    function focus() {
      nextTick(() => button.value?.focus());
    }
    return { props, finalClass, focus, button };
  },
});
</script>

<template>
  <button :class="finalClass" v-bind="props" ref="button"><slot /></button>
</template>
