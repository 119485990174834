
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import router from "@/router";
import PanaceaButton from "@/component/input/PanaceaButton.vue";
import TermsAndConditions from "@/component/TermsAndConditions.vue";
import { useStore } from "vuex";
import clsx from "clsx";
import { IModule, ICart } from "@/service/payment/IPaymentApi";
import { getPaymentSubmitUrl } from "@/service/payment/getPaymentApi";
import usePaymentApi from "@/service/payment/usePaymentApi";
import { useCart } from "@/service/cart/useCart";
import PaymentSummaryTable from "@/component/PaymentSummaryTable.vue";
import useAlert from "@/service/alert/useAlert";
import { useRoute } from 'vue-router';

export default defineComponent({
  components: { PanaceaButton, PaymentSummaryTable, TermsAndConditions },
  props: {
    showPrivate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const payment = usePaymentApi();
    const route = useRoute();
    const terms = ref<string>("");
    const showTerms = ref<boolean>(false);
    const selectedModule = ref<string>("");
    const modules = ref<IModule[]>([]);
    const didAgree = ref<boolean>(false);
    const store = useStore();
    const cart = useCart();
    const alert = useAlert();
    const packageIds = computed(() => store.getters.packageIds);
    const hospitalId = (store.state.apiKey !== "" ? store.state.apiKey : route.params.apiKey) as string;
    const terminalId = (store.state.terminal !== "" ? store.state.terminal : route.params.terminalId) as string;
    const total = computed(cart.getNoTaxesPrice);
    const applicableTaxes = computed(cart.getApplicableTaxes);
    const grandTotal = computed(cart.getTotalPrice);
    const cartItems = computed(cart.getItems);
    const email = ref<String>("")
    const PaymentSubmissionUrl = getPaymentSubmitUrl();
    const loading = ref<boolean>(false);

    function ensurePackages() {
      if (window.history.state.back === null) {
        return router.replace("/");
      }

      if (packageIds.value.length === 0) {
        router.go(-1);
      }
    }

    function onAgreeToTerms(e: any) {
      didAgree.value = !didAgree.value;
    }

    function onModuleSelect(module: string) {
      selectedModule.value = module;
    }

    async function submitForm() {
      let form = {
        module: selectedModule.value,
        hospitalId: hospitalId,
        terminalId: terminalId,
        returnUrlSuccess: `${process.env.VUE_APP_WEB_URL}/success`,
        returnUrlFailure: `${process.env.VUE_APP_WEB_URL}/fail`,
        packageIds: packageIds.value,
        email: email.value.trim(),
      }

      if (!form.module || form.module === "") {
        alert.showErrorMessage('Unable to complete purchase due to missing purchase information!')
        return
      }

      if (form.email === "" || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email.toString()))) {
        alert.showErrorMessage('Please enter a valid email address!')
        return
      }
      
      try {
        loading.value = true;
        await payment.makePurchase(PaymentSubmissionUrl, form);
      } catch (e) {
        loading.value = false;
        alert.showErrorMessage('Something went wrong - Unable to complete purchase!');
      }
    }

    watch(() => cartItems.value, (newLength, oldLength) => {
      if (newLength.length === 0) {
        router.back()
      }
    },
    {
      immediate: true,
      deep: true
    })

    onMounted(async () => {
      ensurePackages();
      try {
        const [
        termsResponse,
        modulesResponse,
      ] = await Promise.all([
        payment.getTermsAndConditions(route.params.hospitalId as string),
        payment.loadModules(route.params.hospitalId as string, props.showPrivate),
      ]);
        terms.value = termsResponse;
        modules.value = modulesResponse;
        selectedModule.value = modulesResponse[0].name;
      } catch (e) {
        alert.showErrorMessage('Something went wrong - Unable to retrieve purchase information!');
      }
    });

    return {
      total,
      clsx,
      cartItems,
      terms,
      grandTotal,
      applicableTaxes,
      showTerms,
      onAgreeToTerms,
      didAgree,
      modules,
      selectedModule,
      onModuleSelect,
      packageIds,
      hospitalId,
      terminalId,
      email,
      PaymentSubmissionUrl,
      submitForm,
      loading
    };
  },
});
