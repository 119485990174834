<script lang="ts">
import { useCart } from "@/service/cart/useCart";
import { defineComponent, computed } from "vue";
import clsx from "clsx";
import PanaceaCounter from "@/component/PanaceaCounter.vue";
import { IPackage } from "@/service/payment/IPaymentApi";
import useAlert from "@/service/alert/useAlert";
import { useStore } from "vuex";

export default defineComponent({
  components: { PanaceaCounter },
  setup(props) {
    const store = useStore();
    const cart = useCart();
    const alert = useAlert();
    const confirmRemoveOne = async (pkg: IPackage) => {
      if (store.state.packageTally[JSON.stringify(pkg)] === 1 && Object.keys(store.state.packageTally).length === 1) {
        let result = await alert.removeAndReturnConfirmation()
        if (!result.value) {
          return
        }
      }
      cart.decrementCart(pkg)
    }

    function showDetails(pkg: IPackage) {
      alert.expandDetails(pkg)
    }

    function getItemTotal(pkg: IPackage) {
      return store.state.packageTally[JSON.stringify(pkg)] * pkg.noTaxesPrice;
    }

    return {
      store,
      clsx,
      row: "grid grid-cols-3 md:grid-cols-4 px-4 py-2",
      addToCart: cart.addToCart,
      removeFromCart: cart.removeFromCart,
      incrementCart: cart.incrementCart,
      total: computed(cart.getNoTaxesPrice),
      applicableTaxes: computed(cart.getApplicableTaxes),
      grandTotal: computed(cart.getTotalPrice),
      cartItems: computed(cart.getOrderedItems),
      confirmRemoveOne,
      getItemTotal,
      showDetails
    };
  },
});
</script>

<template>
  <div
    class="w-full overflow-hidden bg-white border-b md:shadow md:rounded-lg md:border-none"
  >
    <div :class="clsx(row, 'text-white shadow bg-primary')">
      <p class="text-xl text-left">My Cart</p>
    </div>
    <div :class="clsx(row, 'border-b-2')">
      <p class="font-semibold text-left">Name</p>
      <p class="mx-auto font-semibold text-center">Quantity</p>
      <div class="hidden md:block" />
      <p class="font-semibold text-right">Price</p>
    </div>
    <div :class="row" v-for="pkg in cartItems" :key="pkg.id">
      <div class="flex justify-between flex-col md:flex-row md:items-center overflow-hidden">
        <p class="text-left overflow-hidden cursor-pointer" @click="showDetails(pkg)" style="white-space: nowrap; text-overflow: ellipsis">{{ pkg.name }}</p>
        <p class="hover:text-primary text-xs underline md:mx-3" @click="removeFromCart(pkg)">Remove</p>
      </div>
      <p class="flex items-center justify-center overflow-hidden text-lg">
        <panacea-counter
          class="mx-auto"
          :quantity="store.state.packageTally[JSON.stringify(pkg)]"
          @on-increment="incrementCart(pkg)"
          @on-decrement="confirmRemoveOne(pkg)"
        />
      </p>
      <div class="hidden md:block" />
      <p class="text-right">${{ getItemTotal(pkg).toFixed(2) }}</p>
    </div>
    <div :class="clsx(row)">
      <p class="md:opacity-0">Subtotal:</p>
      <div class="" />
      <p class="hidden text-right md:block">Subtotal:</p>
      <p class="text-right">${{ total.toFixed(2) }}</p>
    </div>
    <div :class="clsx(row, 'border-b border-primary')">
      <p class="md:opacity-0">Applicable Taxes</p>
      <div class="" />
      <p class="hidden text-right md:block">Applicable Taxes:</p>
      <p class="text-right">+ ${{ applicableTaxes.toFixed(2) }}</p>
    </div>
    <div :class="clsx(row)">
      <p class="md:opacity-0">Grand Total:</p>
      <div />
      <p class="hidden font-bold text-right md:block">Grand Total:</p>
      <p class="font-bold text-right">${{ grandTotal.toFixed(2) }}</p>
    </div>
  </div>
</template>