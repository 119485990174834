<script lang="ts">
import router, { HOSPITAL_ROUTE } from "@/router";
import { defineComponent, computed, onMounted, ref } from "vue";
import BackButton from "@/component/input/Button/BackButton.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    BackButton,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  setup(props, context) {
    const store = useStore();
    const hospital = computed(() => store.state.hospitalObject);
    const route = useRoute();
    return {
      height: "h-24",
      hospital,
      goback: () => router.back(),
      gohome: () => router.push({ name: HOSPITAL_ROUTE }),
    };
  },
});
</script>

<template>
  <div class="flex h-full min-h-screen w-full">
    <nav
      class="fixed top-0 z-50 flex items-center w-full h-16 px-5 text-xl bg-white border-b shadow md:h-20"
    >
      <div
        class="container flex items-center justify-center w-full h-full mx-auto md:justify-between"
      >
        <div class="flex items-center justify-center h-10 md:mr-auto md:h-14" @click="gohome">
          <img src="/family-mobile.png" class="h-full" alt="panacea-logo" />
        </div>
        <div v-if="hospital" class="hidden ml-auto h-14 md:block">
          <img
            :src="hospital.image"
            class="h-full md:block"
            alt="panacea-logo"
          />
        </div>
        <router-view name="appnav"></router-view>
      </div>
    </nav>
    <div class="mt-16 md:mt-20 md:mb-0">
      <slot />
    </div>
  </div>
</template>
