<script lang="ts">
import { defineComponent } from "vue";
import clsx from "clsx";

export default defineComponent({
  props: {
    quantity: {
      type: Number,
      required: true,
    },
  },
  setup(props, ctx) {
    return {
      clsx,
      inc: () => ctx.emit("on-increment"),
      dec: () => ctx.emit("on-decrement"),
    };
  },
});
</script>

<template>
  <span class="overflow-hidden border rounded-lg">
    <span
      class="p-2 transition-colors duration-200 bg-gray-300 cursor-pointer lg:font-bold lg:p-3"
      @click="dec()"
      >-</span
    >
    <span class="px-4 py-2 bg-white lg:px-6">{{ quantity }}</span>
    <span
      class="p-2 bg-gray-300 cursor-pointer lg:font-bold lg:p-3"
      @click="inc()"
      >+</span
    >
  </span>
</template>