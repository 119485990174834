import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import SelectionPage from '@/views/SelectionPage.vue'
import HospitalPage from '@/views/HospitalPage.vue'
import LocationPage from '@/views/LocationPage.vue'
import PackagePage from '@/views/PackagePage.vue'
import PaymentSummaryPage from '@/views/PaymentSummaryPage.vue'
import SuccessPage from '@/views/SuccessPage.vue'
import FailPage from '@/views/FailPage.vue'
import NotFound from '@/views/NotFound.vue'

export const CHECKOUT_ROUTE = 'Checkout'
export const SELECTION_ROUTE = 'Selection'
export const HOSPITAL_ROUTE = 'Hospital'
export const LOCATION_ROUTE = 'Location'
export const PACKAGES_ROUTE = 'Packages'
export const SUCCESS_ROUTE = 'Success'
export const FAIL_ROUTE = 'Fail'
export const NOT_FOUND = '404'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: HOSPITAL_ROUTE,
    component: HospitalPage,
    meta: {
      hideAppBar: true,
      hideTitle: true,
    },
  },
  {
    path: '/:apiKey/:hospitalId',
    name: SELECTION_ROUTE,
    component: SelectionPage,
    meta: {
      hideTitle: true,
    },
  },
  {
    path: '/:apiKey/:hospitalId/payment',
    name: LOCATION_ROUTE,
    component: LocationPage,
    meta: {
      module: 'Pay',
    },
  },
  {
    path: '/:apiKey/:hospitalId/payment/:terminalId',
    name: PACKAGES_ROUTE,
    components: {
      default: PackagePage,
    },
    meta: {
      module: 'Pay',
    },
  },
  {
    path: '/:apiKey/:hospitalId/payment/:terminalId/checkout',
    name: CHECKOUT_ROUTE,
    component: PaymentSummaryPage,
    meta: {
      module: 'Pay',
    },
  },
  {
    path: '/success',
    name: SUCCESS_ROUTE,
    component: SuccessPage,
  },
  {
    path: '/fail',
    name: FAIL_ROUTE,
    component: FailPage,
  },
  {
    path: "/:catchAll(.*)",
    name: NOT_FOUND,
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// router.beforeEach((to, _, next) => {
//   const excludeRoutes = ["/", "/fail", "/success"];
//   if (excludeRoutes.includes(to.fullPath)) {
//     next()
//   } else {
//     // const id = to.params.hospitalId as string
//     // const payment = usePaymentApi()
//     // payment.loadHospitalByKey(id).then(val => {
//     //   store.commit(SET_HOSPITAL_OBJECT, val)
//     //   next()
//     // })
//   }
// })

export default router
