
import { defineComponent } from "vue";
import clsx from "clsx";

export default defineComponent({
  props: {
    quantity: {
      type: Number,
      required: true,
    },
  },
  setup(props, ctx) {
    return {
      clsx,
      inc: () => ctx.emit("on-increment"),
      dec: () => ctx.emit("on-decrement"),
    };
  },
});
