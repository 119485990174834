
import router, { HOSPITAL_ROUTE } from "@/router";
import { defineComponent, computed, onMounted, ref } from "vue";
import BackButton from "@/component/input/Button/BackButton.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    BackButton,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  setup(props, context) {
    const store = useStore();
    const hospital = computed(() => store.state.hospitalObject);
    const route = useRoute();
    return {
      height: "h-24",
      hospital,
      goback: () => router.back(),
      gohome: () => router.push({ name: HOSPITAL_ROUTE }),
    };
  },
});
