<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import PanaceaButton from "@/component/input/PanaceaButton.vue";
export default defineComponent({ 
  components: { PanaceaButton },
  setup() {
    const route = useRoute();
    const queryParams = route.query;
    return {
      queryParams,
    }
  }
});
</script>

<template>
  <div class="flex flex-col w-screen h-full">
    <div
      class="flex flex-col max-w-xl p-6 m-auto md:bg-white md:rounded-lg md:shadow"
    >
      <div class="mb-10 text-center">
        <i class="fa fa-exclamation-circle fa-5x my-5 text-primary-dark" />
        <p class="text-2xl text-center text-cool-gray-600">
          Oops, something went wrong!
        </p>
      <p class="text-xs mt-2 text-center">
        RefId: {{ queryParams.refId }}
      </p>
      </div>
      <router-link to="/">
        <panacea-button class="w-full h-full" type="button"
          >Try Again</panacea-button
        >
      </router-link>
    </div>
  </div>
</template>

