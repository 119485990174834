
import { defineComponent, onMounted, ref } from "vue";
import { isEnvironment, Environment } from "@/utils/environment";
import LoadingSpinner from "../LoadingSpinner.vue";
import ErrorMessage from "@/component/ErrorMessage.vue";

export default defineComponent({
  components: { LoadingSpinner, ErrorMessage },
  props: {
    load: { type: Function, required: true },
    retryCount: { type: Number, default: 3 },
    retryDelay: { type: Number, default: 3000 },
  },
  setup({ retryDelay, retryCount, load }, context) {
    const currentRetryCount = ref(retryCount);
    const didFinish = ref(false);
    const isLoading = ref(false);

    const error = ref(null);
    onMounted(tryLoad);

    async function tryLoad() {
      didFinish.value = false;
      isLoading.value = true;

      return Promise.resolve(load())
        .then((data) => {
          error.value = null;
          context.emit("success", data);
        })
        .catch((err) => {
          if (isEnvironment(Environment.Staging)) {
            console.log(err);
          }
          error.value = err;
          currentRetryCount.value -= 1;
          if (currentRetryCount.value > 0) {
            context.emit("retry", err);
            setTimeout(tryLoad, retryDelay);
          } else {
            context.emit("fail", err);
          }
        })
        .finally(() => {
          didFinish.value = true;
          isLoading.value = false;
        });
    }
    return {
      retryDelay,
      isLoading,
      retryCount,
      currentRetryCount,
      error,
      didFinish,
    };
  },
});
