
import { ref, defineComponent } from "vue";
import DefaultLayout from "@/component/layout/DefaultLayout.vue";
import AppBarLayout from "@/component/layout/AppBarLayout.vue";

export default defineComponent({
  components: { DefaultLayout, AppBarLayout },
  setup() {
    const visible = ref<boolean>(false);
    const message = ref<string>('An error occurred');

    return {
      visible,
      message,
    };
  }
});
