
import { defineComponent } from "vue";
import router from "@/router";
export default defineComponent({
  methods: {
    goHome() {
      router.replace("/");
    },
  },
});
