import useAlert from "../alert/useAlert";
import { IPackage } from "../payment/IPaymentApi";
import {
  ADD_TO_CART,
  INCREMENT_CART,
  DECREMENT_CART,
  REMOVE_FROM_CART,
} from "@/store/mutation-types";
import { useStore } from "vuex";

export function useCart() {
  const store = useStore();
  const alert = useAlert();

  function addToCart(pkg: IPackage) {
    alert.showCartNotification(pkg);
    store.commit(ADD_TO_CART, pkg);
  }

  function incrementCart(pkg: IPackage) {
    store.commit(INCREMENT_CART, pkg)
  }

  async function decrementCart(pkg: IPackage) {
    store.commit(DECREMENT_CART, pkg);
  }

  async function removeFromCart(pkg: IPackage) {
    const val = await alert.removeAllPackageConfirmation(pkg);
    if (val.isConfirmed) {
      store.commit(REMOVE_FROM_CART, pkg);
    }
  }

  function getItemTotal(pkg: IPackage) {
    const tally = store.state.packageTally[JSON.stringify(pkg)];
    return tally * pkg.noTaxesPrice;
  }

  function getTotalPrice() {
    return store.state.cart.reduce(
      (total: number, pkg: IPackage) => total + pkg.totalPrice,
      0
    );
  }

  function getNoTaxesPrice() {
    return store.state.cart.reduce(
      (total: number, pkg: IPackage) => total + pkg.noTaxesPrice,
      0
    );
  }

  function getApplicableTaxes() {
    return getTotalPrice() - getNoTaxesPrice();
  }

  function getItems(): IPackage[] {
    return store.state.cart;
  }

  function getOrderedItems() {
    return Object.keys(store.state.packageTally).map((pkg) => JSON.parse(pkg))
  }

  return {
    getItems,
    addToCart,
    incrementCart,
    decrementCart,
    removeFromCart,
    getItemTotal,
    getTotalPrice,
    getNoTaxesPrice,
    getApplicableTaxes,
    getOrderedItems,
  };
}
