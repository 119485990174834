
import { defineComponent } from "vue";
import RoundButton from "@/component/input/Button/RoundButton.vue";

export default defineComponent({
  props: {
    className: {
      type: String,
      required: false,
    },
  },
  components: {
    RoundButton,
  },
});
