
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import PanaceaButton from "@/component/input/PanaceaButton.vue";

export default defineComponent({
  components: { PanaceaButton },
  methods: {
    async handleNavigation() {
      if ((window as any).CefSharp) {
        const res = await (window as any).CefSharp.BindObjectAsync(
          "panacea",
          "panacea"
        );

        if ((window as any).panacea) {
          (window as any).panacea.close();
        }
      }
    },
  },
  setup() {
    const route = useRoute();
    const queryParams = route.query;
    return {
      queryParams,
    }
  }
});
