<script lang="ts">
import { CHECKOUT_ROUTE } from "@/router";
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    return {
      cartLength: computed(() => store.state.cart.length),
    };
  },
});
</script>

<template>
  <div class="">
    <button class="relative flex focus:outline-none">
      <i class="fa fa-shopping-cart fa-lg">
        <span
          class="absolute flex items-center justify-center p-3 font-sans text-sm font-bold text-white rounded-full shadow-lg w-7 h-7 bg-accent bottom-3 left-3"
        >
          <span>
            {{ cartLength }}
          </span>
        </span>
      </i>
    </button>
  </div>
</template>