import { IHospital, IPackage } from "@/service/payment/IPaymentApi";
import { createStore } from "vuex";
import * as MutTypes from "./mutation-types";

interface State {
  hospitalObject: IHospital | null,
  hospitalId: String,
  apiKey: String,
  location: String,
  terminal: String,
  room: String,
  cart: IPackage[],
  packageTally: Record<string, number>
}

const increment = (state: State, pkg: IPackage) => {
  const stringify = JSON.stringify(pkg);
  let tally = state.packageTally[stringify];
  tally += 1;
  state.packageTally[stringify] = tally;
  state.cart.push(pkg);
}

const remove = (state: State, pkg: IPackage) => {
  const stringify = JSON.stringify(pkg);
  delete state.packageTally[stringify];
  state.cart = state.cart.filter((item) => item.id !== pkg.id);
}

export default createStore({
  state: {
    hospitalObject: null,
    hospitalId: "",
    apiKey: "",
    location: "",
    terminal: "",
    room: "",
    cart: [] as IPackage[],
    packageTally: {} as Record<string, number>
  },
  getters: {
    packageTotals(state) {
      let sum = 0;
      for (const pkg of state.cart) {
        sum += pkg.noTaxesPrice;
      }
      return sum;
    },
    packageIds(state) {
      return state.cart.map((x) => x.id);
    },
    cartCount(state) {
      return state.cart.length;
    },
  },
  mutations: {
    [MutTypes.ADD_TO_CART](state, pkg: IPackage) {
      const stringify = JSON.stringify(pkg);
      if (!Object.keys(state.packageTally).includes(stringify)) {
        state.packageTally[stringify] = 0;
      }
      increment(state, pkg)
    },
    [MutTypes.INCREMENT_CART](state, pkg: IPackage) {
      increment(state, pkg)
    },
    [MutTypes.DECREMENT_CART](state, pkg: IPackage) {
      const stringify = JSON.stringify(pkg);
      if (state.packageTally[stringify] === 1) {
        remove(state, pkg);
      } else {
        let tally = state.packageTally[stringify];
        tally -= 1;
        state.packageTally[stringify] = tally;
        const index = state.cart.findIndex((item) => item.id === pkg.id);
        state.cart.splice(index, 1);
      }
    },
    [MutTypes.REMOVE_FROM_CART](state, pkg: IPackage) {
      remove(state, pkg)
    },
    [MutTypes.SET_HOSPITAL_KEY](state, hospital: string) {
      state.apiKey = hospital;
    },
    [MutTypes.SET_HOSPITAL_ID](state, hospital: string) {
      state.hospitalId = hospital;
    },
    [MutTypes.SET_HOSPITAL_OBJECT](state, hospital: any) {
      state.hospitalObject = hospital;
    },
    [MutTypes.SET_LOCATION](state, location: string) {
      state.location = location;
    },
    [MutTypes.SET_TERMINAL](state, terminal: string) {
      state.terminal = terminal;
    },
    [MutTypes.SET_ROOM](state, room: string) {
      state.room = room;
    },
    [MutTypes.RESET_ALL](state) {
      state.cart = [];
      state.packageTally = {};
      state.hospitalObject = null;
      state.hospitalId = "";
      state.apiKey = "";
      state.location = "";
      state.terminal = "";
      state.room = "";
    }
  },
  actions: {},
  modules: {},
});
