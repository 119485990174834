import { MockPaymentApi } from "./mock/MockPaymentApi";
import ODataPaymentApi from "./live/ODataPaymentApi";
import axios from "axios";
import join from "url-join";
import { getEnviroment } from "../../utils/environment";

export function getPaymentOdataAPI() {
  switch (getEnviroment()) {
    case "production":
    case "development":
    case "docker":
    case "staging":
      return new ODataPaymentApi(
        axios.create({
          baseURL: getPaymentApiURL("/odata"),
        })
      );
    default:
      return new MockPaymentApi();
  }
}

export function getPaymentApiURL(path = "/") {
  return join(process.env.VUE_APP_API_URL, path);
}

export function getPaymentSubmitUrl() {
  return getPaymentApiURL("/payment/buy");
}
