
import { LOCATION_ROUTE } from "@/router";
import PanaceaCard from "@/component/PanaceaCard.vue";
import { IHospital } from "@/service/payment/IPaymentApi";
import usePaymentApi from "@/service/payment/usePaymentApi";
import { defineComponent, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import useAlert from "@/service/alert/useAlert";
import { useStore } from "vuex";

export default defineComponent({
  components: { PanaceaCard },
  setup(props, ctx) {
    const api = usePaymentApi();
    const route = useRoute();
    const router = useRouter();
    const alert = useAlert();
    const apiKey = route.params.apiKey as string;
    const hospitalId = route.params.hospitalId as string;
    const hospital = ref<IHospital>();

    try {
      api.loadHospitalByKey(apiKey).then((data) => {
        hospital.value = data;
      });
    } catch (e) {
      alert.showErrorMessage(`Something went wrong - Unable to load Hospital data!`);
    }

    function handleBuyServices() {
      if (hospital.value?.hasPaidPackages) {
          router.push({
          name: LOCATION_ROUTE,
          params: { apiKey, hospitalId },
        });
      } else {
        alert.showErrorMessage(`Sorry! This hospital does not offer paid packages.`);
      }
    }

    function handleVideoChat() {
      const url = hospital.value?.inTouchUrl;
      if (!url) {
        alert.showErrorMessage(`Sorry! This hospital does not have video chat support.`);
        return
      }
      window.open(url);
    }

    return {
      hospital,
      handleBuyServices,
      handleVideoChat,
      tileClass:
        "flex flex-col lg:w-full m-3 max-w-xs mx-auto lg:m-2 overflow-hidden bg-white rounded-lg shadow-xl cursor-pointer",
    };
  },
});
