<script lang="ts">
import { nextTick, defineComponent, ref, PropType } from "vue";
import clsx from "clsx";

export default defineComponent({
  props: {
    disabled: { required: false, type: Boolean, default: false },
    placeholder: { type: String },
    listId: { required: true, type: String },
    options: { required: true, type: Array as PropType<any[]> },
  },
  setup(props, ctx) {
    const validationEventName = "validate";
    const currentOption = ref<string>("placeholder");
    const panaceaSelect = ref<HTMLOptionElement>();

    ctx.emit(validationEventName, false);

    function focus() {
      nextTick(() => panaceaSelect.value?.focus());
    }

    function changeHandler(event: any) {
      const newValue = event.target.value;
      currentOption.value = props.options.find(
        (p: any) => p.name === newValue
      );
      const isValid = !!currentOption.value;
      ctx.emit("update:selected", currentOption.value);
      ctx.emit(validationEventName, isValid);
    }

    return { 
      changeHandler, 
      clsx, 
      panaceaSelect, 
      focus 
    };
  },
});
</script>

<template>
  <div class="w-full">
    <form class="max-w-sm my-3 mx-auto">   
      <select ref="panaceaSelect"
        :class="
          clsx(
            'w-full px-3 py-2 rounded-lg bg-gray-200',
            'outline-none focus:outline-none',
            'transition-opacity duration-300',
            disabled ? 'opacity-50' : 'opacity-100',
            disabled && 'cursor-not-allowed'
          )
        "
        :readonly="disabled"
        @input="changeHandler"
        autocomplete="off"
        :id="listId"
      >
      <option selected disabled>
        {{ $props.placeholder || 'None' }}
      </option>
      <option
        v-for="option in options"
        :key="option.name"
        :value="option.name"
      >{{option.name}}</option>
      </select>
    </form>
  </div>
</template>
