
<style scoped>
</style>

<template>
  <div
    class="transition-colors duration-300 rounded-full outline-none cursor-pointer hover:bg-gray-200 hover:p-3 focus:outline-none"
  >
    <slot />
  </div>
</template>