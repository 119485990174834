export enum Environment {
  Production = "production",
  Staging = "staging",
  Docker = "docker",
  Development = "development",
}

export function getEnviroment() {
  return process.env.VUE_APP_ENV || "development";
}

export function isEnvironment(env: Environment) {
  return env === getEnviroment();
}
