<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import router from "@/router";
import PanaceaButton from "@/component/input/PanaceaButton.vue";
import TermsAndConditions from "@/component/TermsAndConditions.vue";
import { useStore } from "vuex";
import clsx from "clsx";
import { IModule, ICart } from "@/service/payment/IPaymentApi";
import { getPaymentSubmitUrl } from "@/service/payment/getPaymentApi";
import usePaymentApi from "@/service/payment/usePaymentApi";
import { useCart } from "@/service/cart/useCart";
import PaymentSummaryTable from "@/component/PaymentSummaryTable.vue";
import useAlert from "@/service/alert/useAlert";
import { useRoute } from 'vue-router';

export default defineComponent({
  components: { PanaceaButton, PaymentSummaryTable, TermsAndConditions },
  props: {
    showPrivate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const payment = usePaymentApi();
    const route = useRoute();
    const terms = ref<string>("");
    const showTerms = ref<boolean>(false);
    const selectedModule = ref<string>("");
    const modules = ref<IModule[]>([]);
    const didAgree = ref<boolean>(false);
    const store = useStore();
    const cart = useCart();
    const alert = useAlert();
    const packageIds = computed(() => store.getters.packageIds);
    const hospitalId = (store.state.apiKey !== "" ? store.state.apiKey : route.params.apiKey) as string;
    const terminalId = (store.state.terminal !== "" ? store.state.terminal : route.params.terminalId) as string;
    const total = computed(cart.getNoTaxesPrice);
    const applicableTaxes = computed(cart.getApplicableTaxes);
    const grandTotal = computed(cart.getTotalPrice);
    const cartItems = computed(cart.getItems);
    const email = ref<String>("")
    const PaymentSubmissionUrl = getPaymentSubmitUrl();
    const loading = ref<boolean>(false);

    function ensurePackages() {
      if (window.history.state.back === null) {
        return router.replace("/");
      }

      if (packageIds.value.length === 0) {
        router.go(-1);
      }
    }

    function onAgreeToTerms(e: any) {
      didAgree.value = !didAgree.value;
    }

    function onModuleSelect(module: string) {
      selectedModule.value = module;
    }

    async function submitForm() {
      let form = {
        module: selectedModule.value,
        hospitalId: hospitalId,
        terminalId: terminalId,
        returnUrlSuccess: `${process.env.VUE_APP_WEB_URL}/success`,
        returnUrlFailure: `${process.env.VUE_APP_WEB_URL}/fail`,
        packageIds: packageIds.value,
        email: email.value.trim(),
      }

      if (!form.module || form.module === "") {
        alert.showErrorMessage('Unable to complete purchase due to missing purchase information!')
        return
      }

      if (form.email === "" || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email.toString()))) {
        alert.showErrorMessage('Please enter a valid email address!')
        return
      }
      
      try {
        loading.value = true;
        await payment.makePurchase(PaymentSubmissionUrl, form);
      } catch (e) {
        loading.value = false;
        alert.showErrorMessage('Something went wrong - Unable to complete purchase!');
      }
    }

    watch(() => cartItems.value, (newLength, oldLength) => {
      if (newLength.length === 0) {
        router.back()
      }
    },
    {
      immediate: true,
      deep: true
    })

    onMounted(async () => {
      ensurePackages();
      try {
        const [
        termsResponse,
        modulesResponse,
      ] = await Promise.all([
        payment.getTermsAndConditions(route.params.hospitalId as string),
        payment.loadModules(route.params.hospitalId as string, props.showPrivate),
      ]);
        terms.value = termsResponse;
        modules.value = modulesResponse;
        selectedModule.value = modulesResponse[0].name;
      } catch (e) {
        alert.showErrorMessage('Something went wrong - Unable to retrieve purchase information!');
      }
    });

    return {
      total,
      clsx,
      cartItems,
      terms,
      grandTotal,
      applicableTaxes,
      showTerms,
      onAgreeToTerms,
      didAgree,
      modules,
      selectedModule,
      onModuleSelect,
      packageIds,
      hospitalId,
      terminalId,
      email,
      PaymentSubmissionUrl,
      submitForm,
      loading
    };
  },
});
</script>

<template>
  <div class="flex w-screen h-full bg-gray-200">
    <terms-and-conditions
      :html="terms"
      :show="showTerms"
      @on-close="showTerms = false"
    />
    <div class="p-3 mx-auto md:container md:max-w-4xl">
      <div class="mb-3">
        <payment-summary-table />
      </div>
      <form
        @submit.prevent="submitForm"
        id="payment"
        class="bg-white md:rounded-lg md:shadow"
      >
        <h2
          class="block px-3 py-2 text-xl text-white shadow md:rounded-t-lg bg-accent md:text-left"
        >
          Payment
        </h2>
        <div class="p-3">
          <input type="hidden" name="module" :value="selectedModule" />

          <div class="mb-3">
            <div class="flex">
              <div v-for="module of modules" :key="module.name">
                <img
                  @click="onModuleSelect(module.name)"
                  :class="
                    clsx(
                      'w-36 md:w-52 mr-3 rounded-lg  border-2',
                      selectedModule === module.name
                        ? 'border-primary'
                        : 'border-transparent'
                    )
                  "
                  :src="module.img"
                  :alt="module.name"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-col mb-3">
            <label for="email">Email receipt to</label>
            <input
              v-model="email"
              class="w-full max-w-sm my-2 p-2 bg-gray-200 border rounded-lg"
              placeholder="email@example.com"
              name="email"
              type="email"
            />
          </div>
          <div class="items-center justify-center mb-6 md:flex">
            <p class="mb-6 md:mb-0">
              By proceeding to Checkout, you agree to our
              <a
                class="text-primary underline cursor-pointer hover:text-accent"
                @click="showTerms = true"
                >Terms and Conditions</a
              >
            </p>
            <panacea-button
              :disabled="loading"
              class="hidden w-full ml-auto md:w-auto md:block"
              type="submit"
              ><span v-if="loading">
                <div class="grid min-h-[140px] w-full place-items-center rounded-lg px-10 overflow-visible">
                  <svg class="text-gray-300 animate-spin" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24">
                    <path
                      d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                      stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path
                      d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                      stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-gray-900">
                    </path>
                  </svg>
                </div>
              </span>
              <span v-else>Checkout <i class="ml-3 text-white fa fa-credit-card fa-lg" /></span>
            </panacea-button>

            <!-- Mobile icon -->
            <button
              :disabled="loading"
              class="fixed outline-none focus:outline-none bottom-20 right-3 md:hidden"
              type="submit"
            >
            <span v-if="loading">
              <div class="grid min-h-[140px] w-full place-items-center rounded-full p-5 overflow-visible bg-primary">
                  <svg class="text-gray-300 animate-spin" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24">
                    <path
                      d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                      stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path
                      d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                      stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-gray-900">
                    </path>
                  </svg>
                </div>
            </span>
            <span v-else>
              <i
                id="checkout-btn"
                :class="
                  clsx(
                    'fa z-0 fa-credit-card fa-2x',
                    'flex items-center justify-center',
                    'z-20 w-16 h-16 ml-auto text-white rounded-full md:hidden bottom-3',
                    'transition-colors duration-300',
                    'bg-primary-dark'
                  )
                "
              />
            </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
