
import { useCart } from "@/service/cart/useCart";
import { defineComponent, computed } from "vue";
import clsx from "clsx";
import PanaceaCounter from "@/component/PanaceaCounter.vue";
import { IPackage } from "@/service/payment/IPaymentApi";
import useAlert from "@/service/alert/useAlert";
import { useStore } from "vuex";

export default defineComponent({
  components: { PanaceaCounter },
  setup(props) {
    const store = useStore();
    const cart = useCart();
    const alert = useAlert();
    const confirmRemoveOne = async (pkg: IPackage) => {
      if (store.state.packageTally[JSON.stringify(pkg)] === 1 && Object.keys(store.state.packageTally).length === 1) {
        let result = await alert.removeAndReturnConfirmation()
        if (!result.value) {
          return
        }
      }
      cart.decrementCart(pkg)
    }

    function showDetails(pkg: IPackage) {
      alert.expandDetails(pkg)
    }

    function getItemTotal(pkg: IPackage) {
      return store.state.packageTally[JSON.stringify(pkg)] * pkg.noTaxesPrice;
    }

    return {
      store,
      clsx,
      row: "grid grid-cols-3 md:grid-cols-4 px-4 py-2",
      addToCart: cart.addToCart,
      removeFromCart: cart.removeFromCart,
      incrementCart: cart.incrementCart,
      total: computed(cart.getNoTaxesPrice),
      applicableTaxes: computed(cart.getApplicableTaxes),
      grandTotal: computed(cart.getTotalPrice),
      cartItems: computed(cart.getOrderedItems),
      confirmRemoveOne,
      getItemTotal,
      showDetails
    };
  },
});
