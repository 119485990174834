<script lang="ts">
import { IPackage } from "@/service/payment/IPaymentApi";
import PackageListItem from "./PackageListItem.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: { PackageListItem },
  props: {
    showNext: { type: Boolean },
    showPrevious: { type: Boolean },
    packages: {
      type: Object as PropType<IPackage[]>,
      required: true,
    },
  },
  methods: {
    addToCart(pkg: IPackage) {
      this.$emit("add-to-cart", pkg);
    },
    nextPackages() {
      this.$emit("on-next");
    },
    prevPackages() {
      this.$emit("on-prev");
    },
  },
});
</script>

<template>
  <div class="flex min-h-full justify-center w-full lg:justify-between">
    <button
      id="left-package-scroll"
      :style="{ visibility: showPrevious ? 'visible' : 'hidden' }"
      class="hidden ml-auto text-gray-400 transition-colors duration-300 rounded outline-none lg:px-3 focus:outline-none hover:text-gray-500 lg:block hover:bg-gray-300"
      @click="prevPackages"
    >
      <i class="fa fa-chevron-left lg:fa-2x" />
    </button>

    <ul
      class="overflow-x-hidden w-full lg:container lg:overflow-y-auto lg:gap-3 lg:min-h-0 lg:py-0 lg:grid lg:grid-cols-3 lg:grid-rows-2"
    >
      <li
        v-for="pkg in packages"
        :key="pkg.id"
        class="m-3 border lg:m-0 lg:my-0"
      >
        <package-list-item class="max-w-24" :pkg="pkg" @on-add="addToCart(pkg)" />
      </li>
    </ul>
    <button
      class="hidden mr-auto text-gray-400 transition-colors duration-300 rounded outline-none lg:px-3 focus:outline-none hover:text-gray-500 lg:block hover:bg-gray-300"
      @click="nextPackages"
      :style="{ visibility: showNext ? 'visible' : 'hidden' }"
    >
      <i class="fa fa-chevron-right lg:fa-2x" />
    </button>
  </div>
</template>
