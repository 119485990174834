<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import PanaceaCard from "@/component/PanaceaCard.vue";
import Loading from "@/component/layout/LoadingLayout.vue";
import { useRoute } from "vue-router";
import usePaymentApi from "@/service/payment/usePaymentApi";
import { ILocation, IRoom, ITerminal } from "@/service/payment/IPaymentApi";
import PanaceaSelect from "@/component/input/PanaceaSelect.vue";
import PanaceaButton from "@/component/input/PanaceaButton.vue";
import router, { PACKAGES_ROUTE } from "@/router";
import useAlert from "@/service/alert/useAlert";
import { useStore } from "vuex";
import * as MutTypes from "@/store/mutation-types";

type SelectComponent = typeof PanaceaSelect;

export default defineComponent({
  components: {
    PanaceaSelect,
    PanaceaButton,
    PanaceaCard,
    Loading
  },
  setup(props, ctx) {
    const store = useStore();
    const route = useRoute();
    const payment = usePaymentApi();
    const alert = useAlert();

    const locationSelect = ref<SelectComponent>();
    const roomSelect = ref<SelectComponent>();
    const bedSelect = ref<SelectComponent>();
    const nextButton = ref<SelectComponent>();

    const locations = ref<ILocation[]>([]);
    const rooms = ref<IRoom[]>([]);
    const beds = ref<ITerminal[]>([]);

    const selectedLocation = ref<ILocation>();
    const selectedRoom = ref<IRoom>();
    const selectedBed = ref<ITerminal>();

    const roomsDisabled = computed(() => selectedLocation.value === void 0);
    const bedsDisabled = computed(() => selectedRoom.value === void 0);
    const submitDisabled = computed(
      () => !selectedLocation.value || !selectedRoom.value || !selectedBed.value
    );

    const apiKey = route.params.apiKey as string;
    const hospitalId = route.params.hospitalId as string;

    async function loadLocations() {
      try {
        let data = await payment.loadLocations(hospitalId)
        if (data.length === 0) {
          alert.showErrorMessage('No locations returned for this hospital!')
          return
        }
        locations.value = data;
      } catch (e) {
        alert.showErrorMessage(`Something went wrong - Unable to load locations!`)
      }
    }

    function handleLocationChange(location: ILocation) {
      selectedLocation.value = location;
      rooms.value = location.rooms;
      roomSelect.value?.focus();
    }

    function handleRoomChange(room: IRoom) {
      selectedRoom.value = room;
      beds.value = room.beds;
      bedSelect.value?.focus();
    }

    function handleBedChange(bed: ITerminal) {
      selectedBed.value = bed;
      nextButton.value?.focus();
    }

    function handleSubmit() {
      const terminalId = selectedBed.value?.id;
      const roomId = selectedRoom.value?.name;
      const locationId = selectedLocation.value?.name;
      if (!terminalId || !roomId || !locationId) {
        return;
      }
      store.commit(MutTypes.SET_LOCATION, locationId);
      store.commit(MutTypes.SET_ROOM, roomId);
      store.commit(MutTypes.SET_TERMINAL, terminalId);

      router.push({
        name: PACKAGES_ROUTE,
        params: {
          apiKey,
          hospitalId,
          terminalId,
        },
      });
    }

    return {
      loadLocations,
      locations,
      rooms,
      beds,
      locationSelect,
      roomSelect,
      bedSelect,
      roomsDisabled,
      bedsDisabled,
      submitDisabled,
      handleLocationChange,
      handleRoomChange,
      handleBedChange,
      handleSubmit,
      nextButton,
    };
  },
});
</script>

<template>
  <div class="w-screen h-full py-3 bg-gray-200 md:px-5">
    <loading :load="loadLocations">
      <template #success>
        <p class="container hidden mx-auto text-xl text-gray-700 lg:block">
          Locate your patient in the hospital
        </p>
        <section class="mt-2">
          <!-- flex wrapper -->
          <div class="container flex flex-col justify-center items-center mx-auto">
            <!-- Card List -->
            <div
              class="flex-col items-center justify-center w-full lg:flex lg:flex-row"
            >
              <!-- Location Card -->
              <panacea-card class="lg:w-full lg:max-w-sm lg:m-1.5 m-5">
                <div class="flex flex-col bg-primary">
                  <i
                    class="w-full h-full py-6 mx-auto text-center text-white text-7xl lg:text-9xl fa fa-location-arrow"
                  />
                </div>
                <template v-slot:bottom class="p-6">
                  <div class="px-3">
                    <p class="mb-2">Select patient location</p>
                    <panacea-select
                      ref="locationSelect"
                      :options="locations"
                      list-id="locations"
                      placeholder="Location/Ward"
                      @update:selected="handleLocationChange"
                    />
                  </div>
                </template>
              </panacea-card>

              <!-- Room Card -->
              <panacea-card class="lg:w-full lg:max-w-sm lg:m-1.5 m-5">
                <div class="flex flex-col bg-accent">
                  <i
                    class="w-full h-full py-6 mx-auto text-center text-white text-7xl lg:text-9xl fa fa-map-marker"
                  />
                </div>
                <template v-slot:bottom class="p-6">
                  <div class="px-3">
                    <p class="mb-2">Select patient room</p>
                    <panacea-select
                      :options="rooms"
                      ref="roomSelect"
                      list-id="rooms"
                      :disabled="roomsDisabled"
                      placeholder="Room"
                      @update:selected="handleRoomChange"
                    />
                  </div>
                </template>
              </panacea-card>

              <!-- Bed Card -->
              <panacea-card class="lg:w-full lg:max-w-sm lg:m-1.5 m-5" text="">
                <div class="flex flex-col bg-primary-dark">
                  <i
                    class="w-full h-full py-6 mx-auto text-center text-white text-7xl lg:text-9xl fa fa-bed"
                  />
                </div>
                <template v-slot:bottom>
                  <div class="px-3">
                    <p class="mb-2">Select patient bed</p>
                    <panacea-select
                      :options="beds"
                      ref="bedSelect"
                      list-id="beds"
                      :disabled="bedsDisabled"
                      placeholder="Bed"
                      @update:selected="handleBedChange"
                    />
                  </div>
                </template>
              </panacea-card>
            </div>

            <div class="flex w-full my-5 px-5 lg:px-0 lg:w-auto lg:mx-3 lg:ml-auto">
              <!-- Submit button -->
              <panacea-button
                :disabled="submitDisabled"
                @click="handleSubmit"
                ref="nextButton"
              >
                <div class="flex items-center justify-center ml-auto">
                  <span class="mr-3">Next</span>
                  <i class="fa fa-angle-right fa-lg" />
                </div>
              </panacea-button>
            </div>
          </div>
        </section>
      </template>
    </loading>
  </div>
</template>
