<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import PanaceaButton from "@/component/input/PanaceaButton.vue";

export default defineComponent({
  components: { PanaceaButton },
  methods: {
    async handleNavigation() {
      if ((window as any).CefSharp) {
        const res = await (window as any).CefSharp.BindObjectAsync(
          "panacea",
          "panacea"
        );

        if ((window as any).panacea) {
          (window as any).panacea.close();
        }
      }
    },
  },
  setup() {
    const route = useRoute();
    const queryParams = route.query;
    return {
      queryParams,
    }
  }
});
</script>

<template>
  <div class="flex flex-col w-screen h-full my-auto">
    <div
      class="flex flex-col max-w-xl p-6 m-auto md:bg-white md:rounded-lg md:shadow"
    >
    <div class="mb-10 text-center">
      <i class="fa fa-shopping-bag fa-5x my-5 text-primary-dark" />
      <p class="text-2xl text-center text-cool-gray-600">
        Thank you for purchasing our services!
      </p>
      <p class="text-xs mt-2 text-center">
        RefId: {{ queryParams.refId }}
      </p>
    </div>
      <router-link to="/">
        <panacea-button
          class="w-full"
          type="button"
          value="Go Back"
          @click="handleNavigation()"
          >Go Back</panacea-button
        >
      </router-link>
    </div>
  </div>
</template>