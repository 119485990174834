<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import PanaceaCard from "@/component/PanaceaCard.vue";
import AppBarLayout from "@/component/layout/AppBarLayout.vue";
import LoadingLayout from "@/component/layout/LoadingLayout.vue";
import { IHospital } from "@/service/payment/IPaymentApi";
import usePaymentApi from "@/service/payment/usePaymentApi";
import router, { SELECTION_ROUTE } from "@/router";
import { useStore } from "vuex";
import * as MutTypes from "@/store/mutation-types";

export default defineComponent({
  components: { AppBarLayout, LoadingLayout, PanaceaCard },
  setup() {
    const payment = usePaymentApi();
    const hospitals = ref<IHospital[]>([]);
    const store = useStore();
    store.commit(MutTypes.SET_HOSPITAL_OBJECT, null);

    async function loadHospitals() {
      const data = await payment.loadHospitals();
      hospitals.value = data;
    }

    function handleSubmit(hospital: IHospital) {
      const apiKey = hospital.apiKey;
      const hospitalId = hospital.id;
      const h = hospitals.value.find((x) => x.id === hospital.id);
      store.commit(MutTypes.SET_HOSPITAL_OBJECT, h);
      store.commit(MutTypes.SET_HOSPITAL_ID, hospital.id);
      store.commit(MutTypes.SET_HOSPITAL_KEY, hospital.apiKey);

      router.push({
        name: SELECTION_ROUTE,
        params: {
          apiKey,
          hospitalId,
        },
      });
    }

    onMounted(() => {
      store.commit(MutTypes.RESET_ALL)
    })

    return {
      loadHospitals,
      hospitals,
      handleSubmit,
    };
  },
});
</script>

<template>
  <div class="flex flex-col w-screen h-full bg-gray-200">
    <div
      class="items-center justify-center w-full py-12 flex flex-col bg-primary-dark"
    >
      <h2 class="p-2 lg:text-6xl text-center text-4xl font-thin text-white">
        Welcome to Panacea&trade; Family
      </h2>
      <p class="p-2 lg:text-xl sm:text-lg text-center text-white">
        Stay close to your hospitalized family and friends anytime anywhere.<br class="hidden md:block" />
        Buy them entertainment services and communicate through video chat.
      </p>
    </div>

    <div class="container mx-auto my-3 p-3 lg:my-6">
      <loading-layout :load="loadHospitals">
        <template #success>
          <p class="hidden mx-auto text-xl text-gray-700 lg:block">
            Select your hospital to continue
          </p>
          <div
            class="mx-auto lg:max-w-4.5xl lg:gap-3 lg:py-3 lg:grid lg:grid-cols-3"
          >
            <panacea-card
              :key="hospital.id"
              v-for="hospital in hospitals"
              class="m-3 cursor-pointer lg:w-full lg:m-0 lg:max-w-xs"
              @click="handleSubmit(hospital)"
              :text="hospital.name"
            >
              <div
                class="flex items-center justify-center w-full h-32 pt-6 bg-white"
              >
                <img class="w-64 mb-4 select-none" :src="hospital.image" />
              </div>
            </panacea-card>
          </div>
        </template>
      </loading-layout>
    </div>
  </div>
</template>

<style>
.panacea-grid-container {
  width: 60rem;
}
</style>