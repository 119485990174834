
import { nextTick, defineComponent, ref, PropType } from "vue";
import clsx from "clsx";

export default defineComponent({
  props: {
    disabled: { required: false, type: Boolean, default: false },
    placeholder: { type: String },
    listId: { required: true, type: String },
    options: { required: true, type: Array as PropType<any[]> },
  },
  setup(props, ctx) {
    const validationEventName = "validate";
    const currentOption = ref<string>("placeholder");
    const panaceaSelect = ref<HTMLOptionElement>();

    ctx.emit(validationEventName, false);

    function focus() {
      nextTick(() => panaceaSelect.value?.focus());
    }

    function changeHandler(event: any) {
      const newValue = event.target.value;
      currentOption.value = props.options.find(
        (p: any) => p.name === newValue
      );
      const isValid = !!currentOption.value;
      ctx.emit("update:selected", currentOption.value);
      ctx.emit(validationEventName, isValid);
    }

    return { 
      changeHandler, 
      clsx, 
      panaceaSelect, 
      focus 
    };
  },
});
