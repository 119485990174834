<script lang="ts">
import * as MutTypes from "@/store/mutation-types";
import Loading from "@/component/layout/LoadingLayout.vue";
import PackageList from "@/component/PackageList.vue";
import PanaceaButton from "@/component/input/PanaceaButton.vue";
import ErrorMessage from "@/component/ErrorMessage.vue";
import clsx from "clsx";
import router, { CHECKOUT_ROUTE } from "@/router";
import usePaymentApi from "@/service/payment/usePaymentApi";
import { IPackage } from "@/service/payment/IPaymentApi";
import { defineComponent, ref, computed, onMounted, onUnmounted } from "vue";
import { useCart } from "@/service/cart/useCart";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import CartButton from "@/component/input/Button/CartButton.vue";
import useAlert from "@/service/alert/useAlert";

export default defineComponent({
  components: {
    PanaceaButton,
    Loading,
    PackageList,
    CartButton,
    ErrorMessage
  },
  setup() {
    const payment = usePaymentApi();
    const store = useStore();
    const route = useRoute();
    const cart = useCart();
    const cartItems = computed(() => store.state.cart);
    const isCartEmpty = computed(() => store.state.cart.length === 0);
    const availablePackages = ref<IPackage[]>([]);
    const paginationIndex = ref(0);
    const packagesPerPage = 6;
    const alert = useAlert();
    const mobile = ref<Boolean>(false);

    const displayedPackages = computed(() => {
      if (!mobile.value) {
        const startIndex = paginationIndex.value * packagesPerPage;
        const endIndex = paginationIndex.value * packagesPerPage + packagesPerPage;
        return availablePackages.value.slice(startIndex, endIndex);
      }
      return availablePackages.value;
    });

    function incrementPaginationIndex() {
      const nextVal = paginationIndex.value + 1;
      if (nextVal * packagesPerPage < availablePackages.value.length) {
        paginationIndex.value = nextVal;
      }
    }

    function decrementPaginationIndex() {
      paginationIndex.value = Math.max(0, paginationIndex.value - 1);
    }

    function handleSubmit() {
      if (isCartEmpty.value) return;
      store.commit(MutTypes.SET_HOSPITAL_ID, route.params.id);
      store.commit(MutTypes.SET_HOSPITAL_KEY, route.params.apiKey);
      store.commit(MutTypes.SET_LOCATION, route.params.locationId);
      store.commit(MutTypes.SET_TERMINAL, route.params.terminalId);
      store.commit(MutTypes.SET_ROOM, route.params.roomId);
      router.push({ name: CHECKOUT_ROUTE });
    }

    function isMobile() {
      let width = window.innerWidth;
      mobile.value = width < 1024;
    }

    async function loadPackages() {
      try {
        const pkgs = await payment.loadPackages(
          route.params.hospitalId as string,
          route.params.terminalId as string
        );
        availablePackages.value = [...pkgs];
        if (pkgs.length === 0) {
          alert.showErrorMessage('No packages returned for this patient!')
        }
      } catch (e) {
        alert.showErrorMessage('Something went wrong - Unable to load packages!')
      }
    }

    onMounted(() => {
      window.addEventListener('resize', isMobile);
      isMobile()
    });

    onUnmounted(() => {
      window.removeEventListener('resize', isMobile);
    });
    
    return {
      loadPackages,
      packagesPerPage,
      availablePackages,
      displayedPackages,
      cart,
      cartItems,
      paginationIndex,
      incrementPaginationIndex,
      decrementPaginationIndex,
      isCartEmpty,
      handleSubmit,
      clsx,
      mobile
    };
  },
});
</script>

<template>
  <div
    class="w-screen h-full py-1 bg-gray-200 md:px-5 lg:py-3 lg:flex lg:items-center lg:flex-col"
  >
    <loading :load="loadPackages">
      <template #success>
        <p
          class="container hidden mx-auto text-xl text-gray-700 lg:block"
        >
          Add packages to cart. Then, click My Cart to review and checkout.
        </p>
        <div class="w-full flex justify-center mt-3">
          <div class="w-full lg:w-3/4">
            <div v-if="availablePackages.length === 0">
              <error-message></error-message>
            </div>
            <package-list
              v-else
              class="w-full mb-7"
              :showNext="
                (paginationIndex + 1) * packagesPerPage < availablePackages.length
              "
              :showPrevious="paginationIndex !== 0"
              :packages="mobile ? availablePackages : displayedPackages"
              @add-to-cart="cart.addToCart"
              @on-next="incrementPaginationIndex"
              @on-prev="decrementPaginationIndex"
            />

            <!-- Desktop Icon -->
            <div class="hidden mx-3 lg:flex lg:ml-auto">
              <div class="ml-auto">
                <panacea-button :disabled="isCartEmpty" @click="handleSubmit">
                  <div class="flex items-center">
                    <span class="mr-3">My Cart</span>
                    <cart-button />
                  </div>
                </panacea-button>
              </div>
            </div>
          </div>

          <!-- Mobile icon -->
          <div class="fixed bottom-20 right-3 lg:hidden">
            <div
              class="absolute right-0 flex items-center justify-center w-8 h-8 overflow-hidden text-sm font-semibold text-white rounded-full bg-accent"
              :style="{ top: '-.5rem' }"
            >
              {{ cartItems.length }}
            </div>
            <i
              id="checkout-btn"
              @click="handleSubmit"
              :class="
                clsx(
                  'fa fa-shopping-cart fa-2x',
                  'flex items-center justify-center',
                  'w-16 h-16 text-white rounded-full',
                  'transition-colors duration-300',
                  isCartEmpty ? 'bg-gray-400' : 'bg-primary-dark'
                )
              "
            />
          </div>
        </div>
      </template>
    </loading>
  </div>
</template>
