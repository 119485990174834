import Swal from "sweetalert2";
import { IPackage } from "../payment/IPaymentApi";

export default function useAlert() {
  function showTerms(text: string) {
    Swal.fire({
      title: "Terms and Conditions",
      padding: 0,
      html: text,
      showClass: { popup: "" },
      hideClass: { popup: "" },
      customClass: {
        header: "sticky top-0 bg-primary z-50 overflow-hidden",
        title: "text-white mt-3 text-xl font-normal",
        content: "text-xs lg:prose-sm text-left",
        popup: "lg:w-3/4",
        cancelButton: "bg-red-500 px-3 py-1 text-white rounded-lg mb-6",
      },
      showCancelButton: true,
      cancelButtonText: "Close",
      buttonsStyling: false,
      showConfirmButton: false,
    });
  }

  async function removeAllPackageConfirmation(pkg: IPackage) {
    return await Swal.fire({
      title: `Are you sure?`,
      text: `Are you sure you want to remove all ${pkg.name}?`,
      padding: 0,
      showClass: { popup: "" },
      hideClass: { popup: "" },
      customClass: {
        header: "sticky top-0 bg-primary z-50 overflow-hidden",
        title: "text-white mt-3 text-xl font-normal",
        content: "m-2 text-left",
        confirmButton: "bg-accent m-1 px-3 py-1 text-white rounded-lg mb-6 hover:bg-primary-dark",
        cancelButton: "bg-accent m-1 px-3 py-1 text-white rounded-lg mb-6 hover:bg-primary-dark",
      },
      showCancelButton: true,
      allowEscapeKey: true,
      buttonsStyling: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
  }

  function showCartNotification(pkg: IPackage) {
    Swal.fire({
      position: "bottom-start",
      icon: "success",
      toast: true,
      showClass: {
        popup: "",
      },
      customClass: {
        popup: "text-sm",
      },
      title: `Added ${pkg.name} to cart`,
      showConfirmButton: false,
      timer: 1500,
    });
  }

  function showErrorMessage(text: string) {
    Swal.fire({
      title: "Error",
      padding: 0,
      html: text,
      showClass: { popup: "" },
      hideClass: { popup: "" },
      customClass: {
        header: "sticky top-0 bg-primary z-50 overflow-hidden",
        title: "text-white mt-3 text-xl font-normal",
        content: "m-2 text-left",
        cancelButton: "bg-accent px-3 py-1 text-white rounded-lg mb-6 hover:bg-primary-dark",
      },
      showCancelButton: true,
      cancelButtonText: "Close",
      buttonsStyling: false,
      showConfirmButton: false,
    })
  }

  async function removeAndReturnConfirmation() {
    return await Swal.fire({
      title: "Are you sure?",
      padding: 0,
      html: `Are you sure you want to empty your cart and return to the previous page?`,
      showClass: { popup: "" },
      hideClass: { popup: "" },
      customClass: {
        header: "sticky top-0 bg-primary z-50 overflow-hidden",
        title: "text-white mt-3 text-xl font-normal",
        content: "m-2 text-left",
        confirmButton: "bg-accent m-1 px-3 py-1 text-white rounded-lg mb-6 hover:bg-primary-dark",
        cancelButton: "bg-accent m-1 px-3 py-1 text-white rounded-lg mb-6 hover:bg-primary-dark",
      },
      showCancelButton: true,
      allowEscapeKey: true,
      buttonsStyling: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    })
  }

  function expandDetails(pkg: IPackage) {
    Swal.fire({
      title: pkg.name,
      padding: 0,
      html: pkg.description,
      showClass: { popup: "" },
      hideClass: { popup: "" },
      customClass: {
        header: "sticky top-0 bg-primary z-50 overflow-hidden",
        title: "text-white mt-3 text-xl font-normal",
        content: "m-2 text-left",
        cancelButton: "bg-accent px-3 py-1 text-white rounded-lg mb-6 hover:bg-primary-dark",
      },
      showCancelButton: true,
      cancelButtonText: "Close",
      buttonsStyling: false,
      showConfirmButton: false,
    })
  }

  return { 
    showCartNotification, 
    removeAllPackageConfirmation, 
    showTerms, 
    showErrorMessage, 
    removeAndReturnConfirmation, 
    expandDetails
  };
}
