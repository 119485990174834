import {
  IPaymentApi,
  KeyValue,
  IPackage,
  IModule,
  IRoom,
  IHospital,
  ILocation,
  ITerminal,
  ICart
} from "../IPaymentApi";
import { AxiosInstance } from "axios";
import qs from 'qs';
import useAlert from "@/service/alert/useAlert";

export default class ODataPaymentApi implements IPaymentApi {
  constructor(private axios: AxiosInstance) {
    this.axios = axios;

    this.axios.interceptors.response.use(
      response => response,
      error => {
        console.error('Axios Error:', error);
        return Promise.reject(error);
      }
    );
  }

  async getTermsAndConditions(hospitalId: string): Promise<string> {
    const response = await this.axios.get(`servers(${hospitalId})/GetTermsAndConditions()`);
    if (!response.data.value) {
      return "Unavailable";
    }
    return response.data.value.replace(/(?:\r\n|\r|\n)/g, "<br>");
  }

  public async loadModules(
    hospitalId: string,
    showPrivate: boolean
  ): Promise<IModule[]> {
    const response = await this.axios.get(
      `servers(${hospitalId})?$expand=AssignedModules($filter=IsPublic eq true;$expand=Module)`
    );
    let data = response.data.AssignedModules;
    if (data.length !== 0) {
      if (!showPrivate) {
        data = data.filter((d: any) => d.IsPublic);
      }
      return data.map((a: any) => {
        return { name: a.Module.Name, img: a.Module.Image };
      });
    }
    return Promise.reject();
  }

  public async loadHospitals(): Promise<IHospital[]> {
    const res: any[] = [];
    const response = await this.axios.get(
        "servers?$select=Id,Name,ApiKey,Image,HasPaidPackages&$filter=IsPublic eq true"
      );
    for (const server of response.data.value) {
      res.push({ id: server.Id, apiKey: server.ApiKey, name: server.Name, image: server.Image, hasPaidPackages: server.HasPaidPackages });
    }
    return res;
  }

  public async loadHospitalByKey(key: string): Promise<IHospital> {
    const res: IHospital[] = [];
    const response = await this.axios.get(
      `servers?$select=Id,Name,ApiKey,Image,InTouchUrl,HasPaidPackages&$filter=ApiKey eq '${key}'`
    );
    for (const server of response.data.value) {
      res.push({
        id: server.id,
        name: server.Name,
        apiKey: server.ApiKey,
        image: server.Image,
        inTouchUrl: server.InTouchUrl,
        hasPaidPackages: server.HasPaidPackages
      });
    }
    return res[0];
  }

  public async loadLocations(hospitalId: string): Promise<ILocation[]> {
    const response = await this.axios.get(`servers(${hospitalId})/GetLocations()`);
      return response.data
  }

  public async loadPackages(
    hospitalId: string,
    terminal: string
  ): Promise<IPackage[]> {
    const response = await this.axios.get(
      `servers(${hospitalId})/GetPackagesForTerminal(terminalId='${terminal}')`
    );
    return response.data.map((p: any) => ({
      id: p.id,
      description: p.description,
      name: p.name,
      noTaxesPrice: p.noTaxesPrice,
      totalPrice: p.totalPrice,
    }));
  }

  public async makePurchase(url: string, form: ICart): Promise<void> {
    const formData = qs.stringify(form)
    let response = await this.axios.post(
      url,
      formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    if (response.status === 200) {
      document.write(response.data);
      document.close();
    } else {
      useAlert().showErrorMessage('Failed to load the new page.');
    }
  }
}
