
import { IPackage } from "@/service/payment/IPaymentApi";
import PackageListItem from "./PackageListItem.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: { PackageListItem },
  props: {
    showNext: { type: Boolean },
    showPrevious: { type: Boolean },
    packages: {
      type: Object as PropType<IPackage[]>,
      required: true,
    },
  },
  methods: {
    addToCart(pkg: IPackage) {
      this.$emit("add-to-cart", pkg);
    },
    nextPackages() {
      this.$emit("on-next");
    },
    prevPackages() {
      this.$emit("on-prev");
    },
  },
});
