
import { IPackage } from "@/service/payment/IPaymentApi";
import { useCart } from "@/service/cart/useCart";
import { defineComponent, PropType, ref } from "vue";
import clsx from "clsx";
import PanaceaCounter from "@/component/PanaceaCounter.vue";
import useAlert from "@/service/alert/useAlert";

export default defineComponent({
  components: { PanaceaCounter },
  props: {
    pkg: {
      type: Object as PropType<IPackage>,
      required: true,
    },
  },
  setup(props) {
    const cart = useCart();
    const pkg = props.pkg;
    const quantity = ref<number>(1);
    const alert = useAlert();

    return {
      clsx,
      pkg,
      quantity,
      incQuantity() {
        quantity.value += 1;
      },
      decQuantity() {
        if (quantity.value == 1) {
          return;
        }
        quantity.value -= 1;
      },
      onAdd(pkg: IPackage) {
        for (let i = 0; i < quantity.value; i++) {
          cart.addToCart(pkg);
        }
      },
      expandDetails(pkg: IPackage) {
        if (pkg.description.trim().length === 0) {
          return
        }
        alert.expandDetails(pkg)
      }
    };
  },
});
