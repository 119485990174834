<script lang="ts">
import { defineComponent } from "vue";
import RoundButton from "@/component/input/Button/RoundButton.vue";

export default defineComponent({
  props: {
    className: {
      type: String,
      required: false,
    },
  },
  components: {
    RoundButton,
  },
});
</script>
<template>
  <round-button>
    <i class="p-2 font-thin text-gray-500 fa-lg fa fa-arrow-left"></i>
  </round-button>
</template>


<style scoped>
</style>