<script lang="ts">
import { LOCATION_ROUTE } from "@/router";
import PanaceaCard from "@/component/PanaceaCard.vue";
import { IHospital } from "@/service/payment/IPaymentApi";
import usePaymentApi from "@/service/payment/usePaymentApi";
import { defineComponent, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import useAlert from "@/service/alert/useAlert";
import { useStore } from "vuex";

export default defineComponent({
  components: { PanaceaCard },
  setup(props, ctx) {
    const api = usePaymentApi();
    const route = useRoute();
    const router = useRouter();
    const alert = useAlert();
    const apiKey = route.params.apiKey as string;
    const hospitalId = route.params.hospitalId as string;
    const hospital = ref<IHospital>();

    try {
      api.loadHospitalByKey(apiKey).then((data) => {
        hospital.value = data;
      });
    } catch (e) {
      alert.showErrorMessage(`Something went wrong - Unable to load Hospital data!`);
    }

    function handleBuyServices() {
      if (hospital.value?.hasPaidPackages) {
          router.push({
          name: LOCATION_ROUTE,
          params: { apiKey, hospitalId },
        });
      } else {
        alert.showErrorMessage(`Sorry! This hospital does not offer paid packages.`);
      }
    }

    function handleVideoChat() {
      const url = hospital.value?.inTouchUrl;
      if (!url) {
        alert.showErrorMessage(`Sorry! This hospital does not have video chat support.`);
        return
      }
      window.open(url);
    }

    return {
      hospital,
      handleBuyServices,
      handleVideoChat,
      tileClass:
        "flex flex-col lg:w-full m-3 max-w-xs mx-auto lg:m-2 overflow-hidden bg-white rounded-lg shadow-xl cursor-pointer",
    };
  },
});
</script>

<template>
  <div
    class="w-screen h-full p-3 bg-gray-200 md:px-5 md:flex md:items-center md:flex-col"
  >
    <p class="container hidden mx-auto text-xl text-gray-700 lg:block">
      How would you like to proceed?
    </p>
    <div class="container mt-3 flex-wrap justify-center flex">
      <panacea-card
        class="w-full sm:w-3/4 lg:w-1/4 lg:m-1.5 m-3 cursor-pointer"
        @click="handleVideoChat"
        text="Video Chat"
      >
        <div class="h-40 bg-primary">
          <i
            class="w-full h-full py-6 mx-auto my-auto text-center text-white text-9xl fa fa-video-camera"
          />
        </div>
      </panacea-card>
      <panacea-card
        class="w-full sm:w-3/4 lg:w-1/4 m-3 lg:m-1.5 cursor-pointer"
        @click="handleBuyServices"
        text="Buy Services "
      >
        <div class="h-40 bg-accent">
          <i
            class="w-full h-full py-6 mx-auto my-auto text-center text-white text-9xl fa fa-shopping-cart"
          />
        </div>
      </panacea-card>
    </div>
  </div>
</template>
