<script lang="ts">
import { defineComponent } from "vue";
import router from "@/router";
export default defineComponent({
  methods: {
    goHome() {
      router.replace("/");
    },
  },
});
</script>

<template>
  <div id="default-layout" class="flex flex-col min-h-screen bg-white">
    <slot />
  </div>
</template>

