<script lang="ts">
import { ref, defineComponent } from "vue";
import DefaultLayout from "@/component/layout/DefaultLayout.vue";
import AppBarLayout from "@/component/layout/AppBarLayout.vue";

export default defineComponent({
  components: { DefaultLayout, AppBarLayout },
  setup() {
    const visible = ref<boolean>(false);
    const message = ref<string>('An error occurred');

    return {
      visible,
      message,
    };
  }
});
</script>

<template>
  <default-layout>
    <app-bar-layout>
      <router-view v-slot="{ Component }">
        <transition name="slide" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </app-bar-layout>
  </default-layout>
</template>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.3s;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}
</style>