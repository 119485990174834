
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    html: String,
    show: Boolean,
  },
  methods: {
    close: function () {
      this.$emit("on-close", true);
    },
  },
});
