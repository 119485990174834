<script lang="ts">
import PanaceaButton from "@/component/input/PanaceaButton.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { PanaceaButton },
  },
);
</script>

<template>
    <div class="text-center">
    <i class="fa fa-exclamation-circle fa-5x my-5 text-primary-dark" />
    <p class="text-2xl text-center text-cool-gray-600 mb-3">
        Oops, something went wrong!
    </p>
    </div>
</template>