<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    html: String,
    show: Boolean,
  },
  methods: {
    close: function () {
      this.$emit("on-close", true);
    },
  },
});
</script>

<template>
  <div
    v-if="show"
    class="fixed flex items-center justify-center w-screen h-full"
  >
    <div
      class="absolute z-30 w-full h-full bg-black opacity-50"
      @click="close"
    />

    <div
      class="absolute z-40 mx-3 my-3 overflow-scroll bg-white rounded-lg top-3 min-h-screen/3 w-3/4 lg:w-1/2 max-h-9/12"
    >
      <h3
        class="sticky top-0 py-3 text-2xl font-thin text-center text-white shadow-md bg-primary"
      >
        Terms and Conditions
      </h3>
      <div class="p-3">
        <p class="mb-6 prose-sm bg-white border-b" v-html="html"></p>
      </div>
      <div class="absolute flex items-center w-full justify-cnter">
        <button
          class="px-12 py-2 mx-auto mb-3 text-white rounded-lg bg-accent focus:outline-none"
          @click="close"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>